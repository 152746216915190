import "../../styles.css";
import { Bucket, IncomingBucketStageOption } from "../../types";
import { GET_CHARTS_RESULTS_URL } from "../../../../api/axios";
import { useCallback, useEffect, useMemo } from "react";
import { useParameterization } from "../../../../hooks/useParameterization";
import BucketButton from "./BucketButton";
import Grid from "@mui/material/Grid";
import useLanguage from "../../../../context/LanguageProvider";
import {
  checkAndSetStoragedBucket,
  getStagesByBucketId,
  handleSetSelectedBucket,
} from "../../utils";

const BucketsButtons = ({ isSticky }) => {
  const { t } = useLanguage();
  const {
    parameterizationState,
    parameterizationDispatch,
    buckets,
    rowsNtController,
    chartsController,
    getChartsResults,
  } = useParameterization();
  const { bucketSelectedOption, selectedStage } = parameterizationState;

  /*********************************** Handlers **************************************************/

  const handleClick = (id: number, nombre: string) => {
    handleSetSelectedBucket(
      { value: id, label: nombre },
      parameterizationDispatch,
      buckets
    );
  };

  /************************************ Efectos al cambiar el bucket ******************************/

  const fetchChartsData = () => {
    if (parameterizationState?.bucketSelectedOption?.value) {
      getChartsResults(
        `${GET_CHARTS_RESULTS_URL}?bucket_id=${parameterizationState?.bucketSelectedOption?.value}`
      );
    }
  };

  const setTabValue = useCallback(() => {
    parameterizationDispatch({
      type: "SET_TAB_VALUE",
      payload: "GASTOS",
    });
  }, [t, parameterizationDispatch]);

  const cleanupControllers = () => {
    chartsController.abort();
    rowsNtController.abort();
  };

  useEffect(() => {
    if (bucketSelectedOption && buckets) {
      const maxIteration =
        getStagesByBucketId(bucketSelectedOption.value, buckets)[0]?.iteraciones
          ?.length - 1;
      if (maxIteration != null) {
        parameterizationDispatch({
          type: "SET_SELECTED_ITERATION",
          payload: getStagesByBucketId(bucketSelectedOption.value, buckets)[0]
            .iteraciones[maxIteration],
        });
      }
    }
  }, [bucketSelectedOption, buckets, parameterizationDispatch]);

  useEffect(() => {
    fetchChartsData();
    setTabValue();

    return cleanupControllers;
  }, [bucketSelectedOption]);

  //Setea la etapa e iteración en función del bucket seleccionado

  const updatedStage = useMemo(() => {
    if (!bucketSelectedOption) {
      return null;
    }
    return (
      getStagesByBucketId(
        bucketSelectedOption.value,
        buckets as IncomingBucketStageOption[]
      ).find((s: any) => s.etapa === selectedStage?.etapa) || null
    );
  }, [bucketSelectedOption, buckets, selectedStage]);

  useEffect(() => {
    if (!bucketSelectedOption) {
      checkAndSetStoragedBucket(buckets, parameterizationDispatch);
    } else if (updatedStage) {
      parameterizationDispatch({
        type: "SET_SELECTED_STAGE",
        payload: updatedStage,
      });
      const maxIteration = updatedStage?.iteraciones
        ? updatedStage?.iteraciones.length - 1
        : null;
      if (maxIteration != null) {
        parameterizationDispatch({
          type: "SET_SELECTED_ITERATION",
          payload: updatedStage?.iteraciones[maxIteration],
        });
      }
    }
  }, [buckets, bucketSelectedOption, updatedStage]);

  /* const findBucketTab = buckets?.findIndex(
    (bucket: Bucket) => bucket.id === bucketSelectedOption?.value
  ); */

  return (
    <Grid
      container
      xs={12} 
      justifySelf={"center"}
      component={"div"}
      gap={1.2}
    >
      {buckets &&
        buckets.map((bucket: Bucket) => {
          return (
            <Grid key={bucket.id}>
              <BucketButton
                id={bucket.id}
                name={bucket.nombre}
                percentage={bucket?.porcentaje}
                handleClick={handleClick}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default BucketsButtons;

/* isSticky ? (
    <Stack>
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={findBucketTab >= 0 ? findBucketTab : undefined}
        sx={{
          "& .css-69z67c-MuiTabs-scroller .css-heg063-MuiTabs-flexContainer": {
            width: "1350px !important",
          },
        }}
      >
        {buckets &&
          buckets.map((bucket: Bucket) => {
            return (
              <Stack
                sx={{ margin: "0 8px 0 8px", padding: "10px 0 25px 0" }}
                key={bucket.id}
              >
                <BucketButton
                  id={bucket.id}
                  name={bucket.nombre}
                  percentage={bucket?.porcentaje}
                  handleClick={handleClick}
                />
              </Stack>
            );
          })}
      </Tabs>
    </Stack>
  ) : ( */