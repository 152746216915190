import { Switch } from "../switch";
import React from "react";
import SpeedDialTooltipOpen from "../buttons/SpeedDialTooltipOpen";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const CardActions: React.FC<{
  checked: boolean;
  handleChangeSwitch: undefined | (() => void);
  item: any;
  handleEdit: () => void;
  handleCopy: () => void;
  handleInfo: () => void;
  handleDelete: () => void;
}> = ({
  checked,
  handleChangeSwitch,
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
}) => (
  <Stack
    sx={{
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginTop: "10px",
    }}
  >
    {handleChangeSwitch ? (
      <Switch
        handleChange={handleChangeSwitch}
        label={checked ? "Activo" : "Inactivo"}
        checked={checked}
        color="primary"
      />
    ) : (
      <Box></Box>
    )}
    <SpeedDialTooltipOpen
      id={item.id}
      handleEdit={handleEdit}
      handleCopy={handleCopy}
      handleInfo={handleInfo}
      handleDelete={handleDelete}
    />
  </Stack>
);

export default CardActions;
