import { DataGrid } from "@mui/x-data-grid";
import { GENERATED_RESOURCES } from "../../api/axios";
import { sortTasksByDate } from "../taskManager/utils";
import { Stack } from "@mui/material";
import { transformDataTaskManager } from "../taskManager/transformData";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useEffect, useState } from "react";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import ComponentLayout from "../layout/ComponentLayout";
import CustomNoRowsOverlay from "../tablesTools/NoRows";
import CustomSkeleton from "../skeleton/CustomSkeleton";
import CustomToolbar from "../customToolbar/CustomToolbar";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useApi from "../../hooks/useApi";
import useLanguage from "../../context/LanguageProvider";

const GeneratedDownloads = () => {
  const { t } = useLanguage();
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(6);

  const {
    data: dataExport,
    isLoading: isLoadingDataExport,
    error: errorDataExport,
  } = useApiQuery(
    `${GENERATED_RESOURCES}/usuario`,
    true,
    t("taskManager.getDownloads.error"),
    undefined
  );

  /* useEffect(() => {
    if (location.state && location.state.tab === "DATA EXPORT") {
      setTabValue("ANALISIS_DATAEXPORT");
    }
  }, [location]); */

  useEffect(() => {
    if (dataExport) {
      const { columns, rows } = transformDataTaskManager(
        t,
        dataExport,
        "ANALISIS_DATAEXPORT",
        buttonsActions
      );
      setColumns(columns);
      setRows(rows);
    } else {
      setColumns([]);
      setRows([]);
    }
  }, [dataExport]);

  const { isLoading: isLoadingDownloadFile, callApi: downloadFile } = useApi(
    undefined,
    "GET",
    t("dataManagement.downloadFile.codes"),
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    true
  );

  const buttonsActions = {
    downloadFile,
  };

  const loadings = [
    {
      open: isLoadingDownloadFile,
      message: t("taskManager.loadingDownloadFile"),
    },
  ];

  return (
    <ComponentLayout
      title={t("generatedDownloads.downloads")}
      icon={<BrowserUpdatedIcon />}
    >
      {loadings.map((loading, index) => (
        <SimpleBackdrop
          key={index}
          open={loading.open}
          message={loading.message}
        />
      ))}
      <Stack width="100%">
        {isLoadingDataExport ? (
          <CustomSkeleton height={6} />
        ) : errorDataExport ? (
          <p>{t("taskManager.error")}</p>
        ) : (
          <Stack style={{ height: "70vh", width: "100%", color: "red" }}>
            <DataGrid
              rows={sortTasksByDate(rows)}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[6, 15, 20, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{
                Toolbar: () => (
                  <CustomToolbar
                    toolbar={true}
                    filterButton={true}
                    densitySelector={true}
                  />
                ),
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu={true}
              hideFooter={false}
              checkboxSelection={false}
              disableSelectionOnClick
              sx={{
                boxShadow: 3,
                fontSize: "12px",
                padding: "0 10px 10px 10px",
              }}
            />
          </Stack>
        )}
      </Stack>
    </ComponentLayout>
  );
};

export default GeneratedDownloads;
