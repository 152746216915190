import { columnOptions } from "../../../../configCriteria/selectOptions";
import { handleElementRepeatedName } from "../../../utils";
import { NUMERIC_FUNCTIONS as dataFunctionsOptions } from "../constants";
import { selectStyles } from "../../../../configCriteria/select.styles";
import { useApiQuery } from "../../../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import Button from "../../../../buttons/Button";
import CheckboxOption from "../../../../forms/CheckboxOption";
import ConfirmationModal from "../../../../dialog/ConfirmationModal";
import FormSelectLayout from "../../../../forms/FormSelectLayout";
import InfoIcon from "@mui/icons-material/Info";
import Select from "../../../../forms/Select";
import SimpleBackdrop from "../../../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useApi from "../../../../../hooks/useApi";
import useLanguage from "../../../../../context/LanguageProvider";
import {
  GET_CRITERIA_FILE_COLUMN_URL,
  GET_MEASURE_URL,
  MEASURE_URL,
} from "../../../../../api/axios";
interface SalesMeasureProps {
  tabValue: string;
  handleFinish: () => void;
  fileSelectedOption?: any;
  functionSelectedOptions: any;
  setFunctionSelectedOptions: any;
  functionOptionsChangeHandler: any;
  columnSelectedOptionsNumerator: any;
  setColumnSelectedOptionsNumerator: any;
  columnOptionsChangeHandlerNumerator: any;
  columnSelectedOptionsDenominator: any;
  setColumnSelectedOptionsDenominator: any;
  columnOptionsChangeHandlerDenominator: any;
  newActionName: string;
  setNewActionName: any;
  configMode: "CREATE" | "EDIT" | "INFO" | "COPY" | "";
  dataGetMeasure?: any;
  columnSelectRef: any;
  columnSelectNumeratorRef: any;
  dataGetSales: any[];
  uniqueValuesColumns?: any[];
  setConfigMode: (value: "CREATE" | "EDIT" | "INFO" | "COPY" | "") => void;
  dataGetExpenses: any;
  dataGetComposed: any;
  measureInCurrency: boolean;
  setMeasureInCurrency: any;
  groupByRows?: any[];
}

const SalesMeasure = ({
  tabValue,
  handleFinish,
  fileSelectedOption,
  functionSelectedOptions,
  setFunctionSelectedOptions,
  functionOptionsChangeHandler,
  columnSelectedOptionsNumerator,
  setColumnSelectedOptionsNumerator,
  columnOptionsChangeHandlerNumerator,
  columnSelectedOptionsDenominator,
  setColumnSelectedOptionsDenominator,
  columnOptionsChangeHandlerDenominator,
  newActionName,
  setNewActionName,
  configMode,
  dataGetMeasure,
  columnSelectRef,
  columnSelectNumeratorRef,
  dataGetSales,
  uniqueValuesColumns,
  setConfigMode,
  dataGetExpenses,
  dataGetComposed,
  groupByRows,
  measureInCurrency,
  setMeasureInCurrency,
}: SalesMeasureProps) => {
  const { t } = useLanguage();
  const functionSelectRef: any = useRef(null);
  const [fileSelected, setFileSelected] = useState({ value: "", label: "" });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [message, setMessage] = useState<string>("");

  /************************* SELECTS *******************************/

  const { isLoading: isLoadingGetFileColumn, data: dataGetFileColumn } =
    useApiQuery(
      GET_CRITERIA_FILE_COLUMN_URL,
      false,
      t("configCriteria.getCriteriaFileColumn.error")
    );

  useEffect(() => {
    dataGetFileColumn &&
      dataGetFileColumn
        .filter((item) => {
          if (
            tabValue ===
            t("dataModelText.measureManagement.measureAdmin.tabs.sales")
          ) {
            return (
              item.nombre ===
              t("dataModelText.measureManagement.measureAdmin.tabs.sales")
            );
          } else {
            return item.nombre === fileSelectedOption.label;
          }
        })
        .map((item: any) => {
          setFileSelected({ value: item.id, label: item.nombre });
        });
  }, [tabValue, functionSelectedOptions]);

  /************************** VALIDACIONES ***************************/

  const validateAddSalesAction = () => {
    if (
      !newActionName ||
      newActionName.trim().length === 0 ||
      !functionSelectedOptions ||
      !columnSelectedOptionsNumerator ||
      columnSelectedOptionsNumerator?.length === 0
    ) {
      return true;
    }
    if (
      functionSelectedOptions?.value === "DIVIDIR" &&
      (!columnSelectedOptionsDenominator ||
        columnSelectedOptionsDenominator?.length === 0)
    ) {
      return true;
    }
    return false;
  };  

  const handleValidateMeasureName = () => {
    const fullList = [...dataGetExpenses, ...dataGetSales, ...dataGetComposed];
    if (newActionName.trim().toUpperCase() === "GASTOS-NO-CONTEMPLADOS") {
      setMessage(t("general.repeatedName"));
    } else {
      handleElementRepeatedName(
        t,
        newActionName,
        fullList,
        dataGetMeasure?.id,
        configMode,
        setMessage
      );
    }
  };

  useEffect(() => {
    handleValidateMeasureName();
  }, [newActionName]);

  const isValidData = () => {
    return !(message !== "" || newActionName.trim() === "");
  };

  /*************************** POST DATOS ************************/

  const closeAndClean = (data: any, newCreation?: boolean) => {
    setColumnSelectedOptionsNumerator(undefined);
    setFunctionSelectedOptions(undefined);
    setColumnSelectedOptionsDenominator(undefined);
    setNewActionName("");
    setMeasureInCurrency(false);
    functionSelectRef.current.clearValue();
    if (newCreation === true) {
      setOpenConfirmationModal(false);
      setConfigMode("CREATE");
    } else {
      handleFinish();
    }
  };

  const { isLoading: isLoadingPostMeasure, callApi: postMeasure } = useApi(
    MEASURE_URL,
    "POST",
    t("dataModel.postNewMeasure.codes"),
    undefined,
    closeAndClean,
    undefined,
    true
  );

  const { isLoading: isLoadingPutMeasure, callApi: putMeasure } = useApi(
    GET_MEASURE_URL(dataGetMeasure?.id),
    "PUT",
    t("dataModel.putMeasure.codes"),
    undefined,
    closeAndClean,
    undefined,
    true
  );

  const handleFinishPost = (newCreation?: boolean) => {
    const completedInfo = {
      nombre: newActionName,
      tipo: "VENTAS",
      operacion: functionSelectedOptions?.value,
      columnas: columnSelectedOptionsNumerator.length
        ? columnSelectedOptionsNumerator?.map((column: any) => {
            return column.value;
          })
        : [columnSelectedOptionsNumerator?.value],
      columnas_denominador: columnSelectedOptionsDenominator?.map(
        (column: any) => {
          return column.value;
        }
      ),
      medida_en_moneda:
        functionSelectedOptions?.value !== "CONTAR" &&
        functionSelectedOptions?.value !== "CONTAR.UNICO"
          ? measureInCurrency
          : false,
    };
    configMode === "EDIT"
      ? putMeasure(
          GET_MEASURE_URL(dataGetMeasure?.id),
          completedInfo,
          newCreation
        )
      : postMeasure(MEASURE_URL, completedInfo, newCreation);
  };

  /*************************** LIMPIEZA Y CIERRE ************************/

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const prepareColumnsToUseOptions = () => {
    return (
      groupByRows?.map((row) => {
        return {
          label: row.column.label,
          value: row.column.value,
        };
      }) || []
    );
  };

  const toggleMeasureInCurrency = () => {
    setMeasureInCurrency((prev) => !prev);
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPostMeasure || isLoadingPutMeasure}
        message={
          isLoadingPostMeasure
            ? t("dataModel.postNewMeasure.loading")
            : t("dataModel.putMeasure.loading")
        }
      />
      <SimpleBackdrop
        open={isLoadingGetFileColumn}
        message={t(
          "dataModelText.measureManagement.configMeasure.salesMeasure.loading"
        )}
      />
      <Stack
        sx={{
          pt: "30px",
          width: "100%",
          gap: "20px",
          marginBottom:
            tabValue !==
            t("dataModelText.measureManagement.measureAdmin.tabs.sales")
              ? !functionSelectedOptions
                ? "80px"
                : 0
              : 0,
        }}
      >
        <FormSelectLayout
          title={t(
            "dataModelText.measureManagement.configMeasure.salesMeasure.measureName"
          )}
          required={true}
        >
          <TextField
            type="text"
            placeholder={t(
              "dataModelText.measureManagement.configMeasure.salesMeasure.newMeasureName"
            )}
            variant="outlined"
            fullWidth
            value={newActionName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewActionName(event.target.value);
            }}
            error={message !== ""}
            helperText={message}
            FormHelperTextProps={{
              sx: {
                color: "var(--text-error)",
                margin: 0,
              },
            }}
          />
        </FormSelectLayout>
        <FormSelectLayout
          title={t(
            "dataModelText.measureManagement.configMeasure.salesMeasure.operation"
          )}
          required={true}
        >
          <Select
            reference={functionSelectRef}
            styles={selectStyles(functionSelectedOptions)}
            options={dataFunctionsOptions}        
            className="cc_select"
            onChange={functionOptionsChangeHandler}
            closeMenuOnSelect
            placeholder={t(
              "dataModelText.measureManagement.configMeasure.salesMeasure.chooseOperation"
            )}
            defaultValue={functionSelectedOptions}
            isClearable
          />
        </FormSelectLayout>
        {functionSelectedOptions?.value === "DIVIDIR" ? (
          <>
            <FormSelectLayout
              title={t(
                "dataModelText.measureManagement.configMeasure.salesMeasure.numerators"
              )}
              required={true}
            >
              <Select
                reference={columnSelectNumeratorRef}
                styles={selectStyles(columnSelectedOptionsNumerator)}
                options={
                  tabValue ===
                  t("dataModelText.measureManagement.measureAdmin.tabs.sales")
                    ? columnOptions(dataGetFileColumn, fileSelected)
                    : prepareColumnsToUseOptions()
                }
                className="cc_select"
                name="Columna"
                onChange={columnOptionsChangeHandlerNumerator}
                placeholder={t(
                  "dataModelText.measureManagement.configMeasure.salesMeasure.chooseColumns"
                )}
                closeMenuOnSelect={false}
                defaultValue={columnSelectedOptionsNumerator}
                isClearable
                isMulti
              />
            </FormSelectLayout>
            <FormSelectLayout
              title={t(
                "dataModelText.measureManagement.configMeasure.salesMeasure.denominators"
              )}
              required={true}
            >
              <Select
                reference={columnSelectRef}
                styles={selectStyles(columnSelectedOptionsDenominator)}
                options={
                  tabValue ===
                  t("dataModelText.measureManagement.measureAdmin.tabs.sales")
                    ? columnOptions(dataGetFileColumn, fileSelected)
                    : prepareColumnsToUseOptions()
                }          
                className="cc_select"           
                onChange={columnOptionsChangeHandlerDenominator}
                placeholder={t(
                  "dataModelText.measureManagement.configMeasure.salesMeasure.chooseColumns"
                )}
                closeMenuOnSelect={false}
                defaultValue={columnSelectedOptionsDenominator}      
                isClearable
                isMulti
              />
            </FormSelectLayout>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <InfoIcon color="primary" fontSize="medium" />
              <Typography
                style={{ color: "var(--text-main)", fontWeight: "600" }}
              >
                {t(
                  "dataModelText.measureManagement.configMeasure.salesMeasure.columnsAddInfo"
                )}
              </Typography>
            </Stack>
          </>
        ) : functionSelectedOptions ? (
          <FormSelectLayout
            title={
              functionSelectedOptions?.value === "SUMAR" ||
              functionSelectedOptions?.value === "SUMA-PRODUCTO"
                ? t(
                    "dataModelText.measureManagement.configMeasure.salesMeasure.columns"
                  )
                : t(
                    "dataModelText.measureManagement.configMeasure.salesMeasure.column"
                  )
            }
            required={true}
          >
            <Select
              reference={columnSelectRef}
              styles={selectStyles(columnSelectedOptionsNumerator)}
              options={
                tabValue ===
                t("dataModelText.measureManagement.measureAdmin.tabs.sales")
                  ? columnOptions(dataGetFileColumn, fileSelected)
                  : prepareColumnsToUseOptions()
              }
              form="cc_form"
              className="cc_select"
              name="Columna"
              onChange={columnOptionsChangeHandlerNumerator}
              placeholder={
                functionSelectedOptions?.value === "SUMAR" ||
                functionSelectedOptions?.value === "SUMA-PRODUCTO"
                  ? t(
                      "dataModelText.measureManagement.configMeasure.salesMeasure.chooseColumns"
                    )
                  : t(
                      "dataModelText.measureManagement.configMeasure.salesMeasure.chooseColumn"
                    )
              }
              closeMenuOnSelect={
                functionSelectedOptions?.value === "SUMAR" ||
                functionSelectedOptions?.value === "SUMA-PRODUCTO"
                  ? false
                  : true
              }
              defaultValue={columnSelectedOptionsNumerator}
              data-testid="select_column_component"
              isClearable
              isMulti={
                functionSelectedOptions?.value === "SUMAR" ||
                functionSelectedOptions?.value === "SUMA-PRODUCTO"
                  ? true
                  : undefined
              }
            />
          </FormSelectLayout>
        ) : (
          <></>
        )}
        {functionSelectedOptions?.value !== "CONTAR" &&
          functionSelectedOptions?.value !== "CONTAR.UNICO" && (
            <CheckboxOption
              toggleCheckbox={() => toggleMeasureInCurrency()}
              checked={measureInCurrency}
              id={`medida-ventas-en-moneda`}
              labelText={t(
                "dataModelText.measureManagement.configMeasure.measureInCurrency"
              )}
            />
          )}
      </Stack>

      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleConfirmationModal}
        handleAccept={() => handleFinishPost(false)}
        message={
          configMode === "EDIT"
            ? t(
                "dataModelText.measureManagement.configMeasure.salesMeasure.modifyMeasure"
              )
            : t(
                "dataModelText.measureManagement.configMeasure.salesMeasure.createNewMeasure"
              )
        }
        title={t(
          "dataModelText.measureManagement.configMeasure.salesMeasure.newMeasure"
        )}
      >
        <Button
          title={"Aceptar y crear otra medida"}
          color="outlined"
          type="button"
          onClick={() => handleFinishPost(true)}
          width={320}
          margin={false}
        />
      </ConfirmationModal>
      {tabValue ===
        t("dataModelText.measureManagement.measureAdmin.tabs.sales") && (
        <Stack
          sx={{
            flexDirection: "row",
            position: "absolute",
            bottom: "20px",
          }}
        >
          <Button
            title={t(
              "dataModelText.measureManagement.configMeasure.salesMeasure.cancel"
            )}
            color="light-grey"
            type="button"
            onClick={() => closeAndClean(false)}
          />
          <Button
            title={t(
              "dataModelText.measureManagement.configMeasure.salesMeasure.accept"
            )}
            color="blue-greeny"
            type="submit"
            onClick={handleConfirmationModal}
            disabled={!isValidData() || validateAddSalesAction()}
          />
        </Stack>
      )}
    </>
  );
};

export default SalesMeasure;
