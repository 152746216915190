import * as React from "react";
import { getUserCompany } from "../company/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import logoPhi from "../../assets/logoPhiWhiteCompleted.png";
import Modal from "@mui/material/Modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  companySelectorStyle,
  Drawer,
  DrawerHeader,
  menu_logo,
  menu_logo_container_header,
} from "../../styles/app-styles";
import {
  dashboardCollapseList,
  menuItemsOptions,
  parameterModelCollapseList,
  dataExportModellingCollapseList,
  simulatorCollapseList,
  validationsCollapseList,
  marketBasketCollapseList,
  generatedDownloadsCollapseList,
} from "./utils";
import AppBarComponent from "./AppBarComponent";
import ListItemComponent from "./ListItemComponent";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import CompanySelector from "../company/CompanySelector";
import { returnItemRole } from "../roles/rolesManagement";

export default function MiniDrawer() {
  const theme = useTheme();
  const { userRolData }: any = useInfoContext();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [openBasicModal, setOpenBasicModal] = useState(false);
  const handleOpenBasicModal = () => setOpenBasicModal(true);
  const handleCloseBasicModal = () => setOpenBasicModal(false);
  const [dataGetUserCompany, setDataGetUserCompany] = useState<any>(null);
  const [openMenuList, setOpenMenuList] = useState({
    openParameterModel: false,
    openParameterization: false,
    openDashboard: false,
    openDataExportModelling: false,
    openSimulator: false,
    openValidations: false,
    openMarketBasket: false,
    openDownloads: false,
  });
  const { setCompany }: any = useInfoContext();
  const { t } = useLanguage();

  const backgroundColor =
    dataGetUserCompany?.nombre === "juan_test" ||
    dataGetUserCompany?.nombre === "abru_test"
      ? process.env.REACT_APP_MENU_COLOR_JUAN2 ||
        process.env.REACT_APP_MENU_COLOR ||
        "var(--bg-main)"
      : dataGetUserCompany?.nombre === "nah_test"
      ? process.env.REACT_APP_MENU_COLOR_NAHUEL ||
        process.env.REACT_APP_MENU_COLOR ||
        "var(--bg-main)"
      : dataGetUserCompany?.nombre === "benja_test"
      ? process.env.REACT_APP_MENU_COLOR_BENJA ||
        process.env.REACT_APP_MENU_COLOR ||
        "var(--bg-main)"
      : process.env.REACT_APP_MENU_COLOR || "var(--bg-main)";

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const goHome = () => {
    navigate("/inicio");
    setOpen(false);
  };

  const handleGetCompanyResponse = (status: number, data: any) => {
    if (status === 200) {
      setDataGetUserCompany(data);
      setCompany({ value: data.id, label: data.nombre_visual });
    }
  };

  useEffect(() => {
    getUserCompany(axiosPrivate, handleGetCompanyResponse);
  }, []);

  const handleOpenStates = (listOption: string) => {
    switch (listOption) {
      case t("menuItemsOptions.modelParameterization.title"):
        return openMenuList.openParameterModel;
      case "Dashboard":
        return openMenuList.openDashboard;
      case "Data Export Modelling":
        return openMenuList.openDataExportModelling;
      case t("menuItemsOptions.simulator.title"):
        return openMenuList.openSimulator;
      case t("menuItemsOptions.validations.title"):
        return openMenuList.openValidations;
      case t("menuItemsOptions.marketBasketCollapseList.title"):
        return openMenuList.openMarketBasket;
      default:
        return false;
      case t("menuItemsOptions.generatedDownloads.title"):
        return openMenuList.openDownloads;
    }
  };

  const handleCollapseList = (listOption: string) => {
    switch (listOption) {
      case t("menuItemsOptions.modelParameterization.title"):
        return parameterModelCollapseList(t);
      case "Dashboard":
        return dashboardCollapseList(t);
      case "Data Export Modelling":
        return dataExportModellingCollapseList(t, userRolData);
      case t("menuItemsOptions.simulator.title"):
        return simulatorCollapseList(t);
      case t("menuItemsOptions.validations.title"):
        return validationsCollapseList(t);
      case t("menuItemsOptions.marketBasketCollapseList.title"):
        return marketBasketCollapseList(t);
      case t("menuItemsOptions.generatedDownloads.title"):
        return generatedDownloadsCollapseList(t, userRolData);
      default:
        return [];
    }
  };

  const handleClickOpenMenu = (listOption: string) => {
    switch (listOption) {
      case t("menuItemsOptions.modelParameterization.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openParameterModel: !prev.openParameterModel,
        }));
        break;
      case t("menuItemsOptions.validations.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openValidations: !prev.openValidations,
        }));
        break;
      case "Dashboard":
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openDashboard: !prev.openDashboard,
        }));
        break;
      case "Data Export Modelling":
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openDataExportModelling: !prev.openDataExportModelling,
        }));
        break;
      case t("menuItemsOptions.simulator.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openSimulator: !prev.openSimulator,
        }));
        break;
      case t("menuItemsOptions.marketBasketCollapseList.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openMarketBasket: !prev.openMarketBasket,
        }));
        break;
      case t("menuItemsOptions.generatedDownloads.title"):
        setOpenMenuList((prev: typeof openMenuList) => ({
          ...prev,
          openDownloads: !prev.openDownloads,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        goHome={goHome}
        handleOpenBasicModal={handleOpenBasicModal}
      />
      <Drawer
        variant="permanent"
        open={open}
        onMouseOver={handleDrawerOpen} // Sacando esto se saca el open en el hover
        onMouseOut={handleDrawerClose} // Sacando esto se saca el open en el hover
        sx={{
          background: backgroundColor,
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper":
            { backgroundColor: backgroundColor }, // Sección abajo del menú
          "& .MuiPaper-root.MuiDrawer-paperAnchorLeft": {
            backgroundColor: backgroundColor,
          }, // Sección abajo del menú
        }}
      >
        <DrawerHeader
          sx={{
            background: backgroundColor,
            "&.MuiPaper-root": { backgroundColor: backgroundColor },
          }}
        >
          <Box sx={menu_logo_container_header} onClick={goHome}>
            <img style={menu_logo} src={logoPhi} alt="logo" />
          </Box>
          <IconButton onClick={handleDrawer}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ backgroundColor: backgroundColor }}>
          {menuItemsOptions(t).map((item, index) => {
            //Visualización de pantallas según rol de usuario
            return (
              returnItemRole(item, userRolData, t) && (
                <ListItemComponent
                  key={index}
                  item={item}
                  navigate={navigate}
                  open={open}
                  handleClickOpenMenu={handleClickOpenMenu}
                  handleOpenStates={handleOpenStates}
                  handleCollapseList={handleCollapseList}
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                />
              )
            );
          })}
        </List>
      </Drawer>
      <Box>
        <Modal
          open={openBasicModal}
          onClose={handleCloseBasicModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={companySelectorStyle}>
            <CompanySelector
              menu={true}
              handleCloseBasicModal={handleCloseBasicModal}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
