import AnalyticsIcon from "@mui/icons-material/Analytics";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalculateIcon from "@mui/icons-material/Calculate";
import ConstructionIcon from "@mui/icons-material/Construction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import IsoIcon from "@mui/icons-material/Iso";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import MonitorIcon from "@mui/icons-material/Monitor";
import RuleIcon from "@mui/icons-material/Rule";
import SchemaIcon from "@mui/icons-material/Schema";
import SettingsIcon from "@mui/icons-material/Settings";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import SpeedIcon from "@mui/icons-material/Speed";
import StraightenIcon from "@mui/icons-material/Straighten";
import TableChartIcon from "@mui/icons-material/TableChart";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import DownloadIcon from "@mui/icons-material/Download";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import AddchartIcon from "@mui/icons-material/Addchart";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export const isOptionSelected = (page: string, t: any) => {
  const menuItems = {
    [t("menuItemsOptions.companyParameterization.title")]: [
      t("menuItemsOptions.companyParameterization.url"),
    ],
    [t("menuItemsOptions.dataManagement.title")]: [
      t("menuItemsOptions.dataManagement.url"),
    ],
    [t("menuItemsOptions.validations.title")]: [
      t("menuItemsOptions.validations.adminValidations.url"),
      t("menuItemsOptions.validations.matchesDashboard.url"),
    ],
    [t("menuItemsOptions.criteriaManagement.title")]: [
      t("menuItemsOptions.criteriaManagement.url"),
    ],
    [t("menuItemsOptions.configPreProratedExpenses.title")]: [
      t("menuItemsOptions.configPreProratedExpenses.url"),
    ],
    [t("menuItemsOptions.configProRataExpenses.title")]: [
      t("menuItemsOptions.configProRataExpenses.url"),
    ],
    [t("menuItemsOptions.preProrationParameterization.title")]: [
      t("menuItemsOptions.preProrationParameterization.url"),
    ],
    [t("menuItemsOptions.modelParameterization.title")]: [
      t("menuItemsOptions.parameterizationCollapseList.assignStages.url"),
      t(
        "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.url"
      ),
      t("menuItemsOptions.parameterizationCollapseList.calculationReport.url"),
    ],
    ["Dashboard"]: [
      t("menuItemsOptions.dashboardCollapseList.indicators.url"),
      t("menuItemsOptions.dashboardCollapseList.graphics.url"),
      t("menuItemsOptions.dashboardCollapseList.powerBi.url"),
      t("menuItemsOptions.dashboardCollapseList.analysis.url"),
    ],
    [t("menuItemsOptions.controlPanel.title")]: [
      t("menuItemsOptions.controlPanel.url"),
    ],
    [t("menuItemsOptions.taskManager.title")]: [
      t("menuItemsOptions.taskManager.url"),
    ],
    [t("menuItemsOptions.indicatorManagement.title")]: [
      t("menuItemsOptions.indicatorManagement.url"),
    ],
    ["Data Export Modelling"]: [
      t(
        "menuItemsOptions.dataExportModellingCollapseList.measureManagement.url"
      ),
      t(
        "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.url"
      ),
      t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.url"
      ),
      t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.url"
      ),
      t("menuItemsOptions.dataExportModellingCollapseList.downloadReport.url"),
    ],
    [t("menuItemsOptions.simulator.title")]: [
      t("menuItemsOptions.simulatorCollapseList.salesForecast.url"),
      t("menuItemsOptions.simulatorCollapseList.simulationRules.url"),
      `${t(
        "menuItemsOptions.simulatorCollapseList.simulationRules.url"
      )}/configuracion-regla`,
      t("menuItemsOptions.simulatorCollapseList.simulations.url"),
      "/delta/configuracion-simulaciones",
      "/delta/sales-forecast-resultado",
      "/delta/simulacion-resultado",
    ],
    [t("menuItemsOptions.generatedDownloads.title")]: [
      t("menuItemsOptions.generatedDownloadsCollapseList.downloadAll.url"),
      t("menuItemsOptions.generatedDownloadsCollapseList.downloadByUser.url"),
    ],
    [t("menuItemsOptions.marketBasketCollapseList.title")]: [
      t("menuItemsOptions.marketBasketCollapseList.admin.url"),
      t("menuItemsOptions.marketBasketCollapseList.execution.url"),
      t("menuItemsOptions.marketBasketCollapseList.analysis.url"),
    ],
  };

  return (
    menuItems[page]?.some((url) => location.pathname === `/${url}`) || false
  );
};

export const menuItemsOptions = (t: any) => [
  {
    title: t("menuItemsOptions.companyParameterization.title"),
    url: t("menuItemsOptions.companyParameterization.url"),
    icon: AssignmentTurnedInIcon,
  },
  {
    title: t("menuItemsOptions.dataManagement.title"),
    url: t("menuItemsOptions.dataManagement.url"),
    icon: UploadFileIcon,
  },
  {
    title: t("menuItemsOptions.validations.title"),
    url: "",
    icon: FactCheckIcon,
  },
  {
    title: t("menuItemsOptions.criteriaManagement.title"),
    url: t("menuItemsOptions.criteriaManagement.url"),
    icon: ConstructionIcon,
  },
  {
    title: t("menuItemsOptions.preProrationParameterization.title"),
    url: t("menuItemsOptions.preProrationParameterization.url"),
    icon: FormatListBulletedIcon,
  },
  {
    title: t("menuItemsOptions.configProRataExpenses.title"),
    url: t("menuItemsOptions.configProRataExpenses.url"),
    icon: SettingsIcon,
  },
  {
    title: t("menuItemsOptions.modelParameterization.title"),
    url: "",
    icon: IsoIcon,
  },
  {
    title: t("menuItemsOptions.indicatorManagement.title"),
    url: t("menuItemsOptions.indicatorManagement.url"),
    icon: AnalyticsIcon,
  },
  {
    title: t("menuItemsOptions.dashboard.title"),
    url: "",
    icon: AutoAwesomeMosaicIcon,
  },
  {
    title: t("menuItemsOptions.taskManager.title"),
    url: t("menuItemsOptions.taskManager.url"),
    icon: AssignmentIcon, //SettingsIcon
  },
  {
    title: t("menuItemsOptions.dataExportModelling.title"),
    url: "",
    icon: SchemaIcon,
  },
  {
    title: t("menuItemsOptions.simulator.title"),
    url: "",
    icon: MonitorIcon,
  },
  {
    title: t("menuItemsOptions.generatedDownloads.title"),
    url: "",
    icon: BrowserUpdatedIcon,
  },

  {
    title: t("menuItemsOptions.marketBasketCollapseList.title"),
    url: "",
    icon: TroubleshootIcon,
  },
];

export const parameterModelCollapseList = (t: any) => [
  {
    title: t(
      "menuItemsOptions.parameterizationCollapseList.assignStages.title"
    ),
    url:
      "/" + t("menuItemsOptions.parameterizationCollapseList.assignStages.url"),
    icon: LinearScaleIcon,
  },
  {
    title: t(
      "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.title"
    ),
    url:
      "/" +
      t(
        "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.url"
      ),
    icon: CalculateIcon,
  },
  {
    title: t(
      "menuItemsOptions.parameterizationCollapseList.calculationReport.title"
    ),
    url:
      "/" +
      t("menuItemsOptions.parameterizationCollapseList.calculationReport.url"),
    icon: TableChartIcon,
  },
];

export const dashboardCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.dashboardCollapseList.indicators.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.indicators.url"),
    icon: DataThresholdingIcon,
  },
  {
    title: t("menuItemsOptions.dashboardCollapseList.graphics.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.graphics.url"),
    icon: BarChartIcon,
  },
  {
    title: t("menuItemsOptions.dashboardCollapseList.powerBi.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.powerBi.url"),
    icon: DashboardIcon,
  },
  {
    title: t("menuItemsOptions.dashboardCollapseList.analysis.title"),
    url: "/" + t("menuItemsOptions.dashboardCollapseList.analysis.url"),
    icon: AddchartIcon,
  },
];

export const dataExportModellingCollapseList = (t: any, userRolData) => [
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.title"
    ),
    url:
      "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.url"
      ),
    icon: GroupWorkIcon,
    disabled:
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion_y_edicion === false &&
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion === false &&
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion_y_creacion === false,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.measureManagement.title"
    ),
    url:
      "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.measureManagement.url"
      ),
    icon: StraightenIcon,
    disabled:
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion_y_edicion === false &&
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion === false &&
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion_y_creacion === false,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.title"
    ),
    url:
      "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.url"
      ),
    icon: AnalyticsIcon,
    disabled:
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion_y_edicion === false &&
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion === false &&
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .visualizacion_y_creacion === false,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.title"
    ),
    url:
      "/" +
      t(
        "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.url"
      ),
    icon: SmartDisplayIcon,
    disabled:
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis
        .ejecucion === false,
  },
  {
    title: t(
      "menuItemsOptions.dataExportModellingCollapseList.downloadReport.title"
    ),
    url:
      "/" +
      t("menuItemsOptions.dataExportModellingCollapseList.downloadReport.url"),
    icon: DownloadIcon,
    disabled: userRolData?.descargar_reporte.visualizacion_y_edicion === false,
  },
];

export const simulatorCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.simulatorCollapseList.salesForecast.title"),
    url: t("menuItemsOptions.simulatorCollapseList.salesForecast.url"),
    icon: SmartDisplayIcon,
  },
  {
    title: t("menuItemsOptions.simulatorCollapseList.simulationRules.title"),
    url: t("menuItemsOptions.simulatorCollapseList.simulationRules.url"),
    icon: SpeedIcon,
  },
  {
    title: t("menuItemsOptions.simulatorCollapseList.simulations.title"),
    url: t("menuItemsOptions.simulatorCollapseList.simulations.url"),
    icon: AutoGraphIcon,
  },
  // {
  //   title: t("menuItemsOptions.simulatorCollapseList.simulationSchema.title"),
  //   url: t("menuItemsOptions.simulatorCollapseList.simulationSchema.url"),
  //   icon: SchemaIcon,
  // },
  // {
  //   title: t("menuItemsOptions.simulatorCollapseList.registeredRoutes.title"),
  //   url: t("menuItemsOptions.simulatorCollapseList.registeredRoutes.url"),
  //   icon: RouteIcon,
  // },
  // {
  //   title: t("menuItemsOptions.simulatorCollapseList.simulationsResults.title"),
  //   url: t("menuItemsOptions.simulatorCollapseList.simulationsResults.url"),
  //   icon: AutoGraphIcon,
  // },
];

export const validationsCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.validations.adminValidations.title"),
    url: "/" + t("menuItemsOptions.validations.adminValidations.url"),
    icon: RuleIcon,
  },
  {
    title: t("menuItemsOptions.validations.matchesDashboard.title"),
    url: "/" + t("menuItemsOptions.validations.matchesDashboard.url"),
    icon: JoinLeftIcon,
  },
];

export const marketBasketCollapseList = (t: any) => [
  {
    title: t("menuItemsOptions.marketBasketCollapseList.admin.title"),
    url: "/" + t("menuItemsOptions.marketBasketCollapseList.admin.url"),
    icon: DataSaverOnIcon,
  },
  {
    title: t("menuItemsOptions.marketBasketCollapseList.analysis.title"),
    url: "/" + t("menuItemsOptions.marketBasketCollapseList.analysis.url"),
    icon: PlaylistAddCheckCircleIcon,
  },
];

export const generatedDownloadsCollapseList = (t: any, userRolData) => [
  {
    title: t(
      "menuItemsOptions.generatedDownloadsCollapseList.downloadAll.title"
    ),
    url:
      "/" +
      t("menuItemsOptions.generatedDownloadsCollapseList.downloadAll.url"),
    icon: DownloadForOfflineIcon,
    disabled:
      userRolData?.descargas.visualizacion_y_descargas_totales === false,
  },
  {
    title: t(
      "menuItemsOptions.generatedDownloadsCollapseList.downloadByUser.title"
    ),
    url:
      "/" +
      t("menuItemsOptions.generatedDownloadsCollapseList.downloadByUser.url"),
    icon: DownloadIcon,
    disabled:
      userRolData?.descargas.visualizacion_y_descargas_usuario === false,
  },
];

export const redirectMenuItem = (
  item: any,
  navigate: any,
  handleDrawerClose: any,
  getViewTitle?: any
) => {
  item.url !== "" && handleDrawerClose();

  if (item.url !== "") {
    getViewTitle && getViewTitle();
    navigate(`/${item.url}`);
  }
};
