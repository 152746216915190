import { makeStyles } from "@mui/styles";

// ------------------------- Admin View & Panel -----------------------------

export const no_cards_error_container_styles = (height) => {
  return {
    alignItems: "center",
    display: "flex",
    height: height ? height : "50vh",
    justifyContent: "center",
    width: "95%",
  };
};

export const no_cards_error_typography_styles = {
  fontWeight: "700",
  color: "var(--text-info)",
};

// ------------------------ Cards ----------------------------------

export const kpi_cards_container_styles = {
  backgroundColor: "var(--bg-light)",
  borderRadius: "10px",
  boxShadow: "0px 18px 10px -10px rgb(0 0 0 /30%)",
  marginTop: "0px !important",
  padding: "20px",
  width: "100%",
};

export const kpi_item_box_styles = (posee_error: boolean) => {
  return {
    backgroundColor: posee_error ? "var(--light-red)" : "white",
    borderRadius: "20px",
    color: "var(--text-main)",
    padding: "10px",
  };
};

export const left_key_text_styles = {
  color: "var(--text-info)",
  display: "inline",
  fontSize: "12px",
  fontWeight: "300",
  textAlign: "left",
  textTransform: "capitalize",
};

export const card_subtitle_styles = {
  color: "var(--text-main)",
  fontWeight: "700",
  textAlign: "left",
  textTransform: "uppercase",
};

export const card_title_styles = {
  color: "var(--text-main)",
  fontSize: "14px",
  fontWeight: "800",
  lineHeight: "1.1",
  padding: "0 !important",
  textTransform: "uppercase",
  width: "98%",
};

export const typography_left_key_styles = {
  color:"var(--text-main)",
  display: "inline",
  fontWeight: "700",
  letterSpacing: "-0.5px",
  textAlign: "left",
  textTransform: "uppercase",
}

// ------------------------ Config View -----------------------------

export const arrow_back_icon_styles = {
  fontSize: "35px",
  marginRight: "10px",
  "& :hover": { cursor: "pointer", color: "#f90d4a" },
};

export const input_name_container_styles = {
  alignItems: "flex-start",
  gap: 4,
  width: "100%",
};

export const shadow_boxes_container_styles = {
  flexDirection: "row",
  gap: 4,
  width: "100%",
};

export const columns_error_typography_styles = {
  color: "var(--text-error)",
  fontWeight: "bold",
  height: "30px",
  textAlign: "center",
};

export const config_view_buttons_container = {
  bottom: "20px",
  flexDirection: "row",
  position: "absolute",
};

// ------------------------ Buttons ------------------------

export const button_styles = (
  width: string,
  bgcolor: string,
  disabled?: boolean,
  margin?: boolean
) => {
  return {
    alignItems: "center",
    backgroundColor: disabled
      ? "var(--bg-info)"
      : bgcolor === "outlined"
      ? "white"
      : bgcolor,
    borderRadius: "30px",
    border: bgcolor === "outlined" ? "3px solid var(--bg-main)" : "none",
    color: disabled
      ? "var(--text-disabled)"
      : bgcolor === "outlined"
      ? "var(--text-main)"
      : "white",
    cursor: disabled ? "not-allowed" : "normal",
    display: "flex",
    fontSize: "14px",
    fontWeight: bgcolor === "outlined" ? "bold" : ("100" as any),
    height: "45px",
    justifyContent: "center",
    letterSpacing: "2px",
    margin: margin === false ? "0px" : "10px 10px",
    opacity: disabled ? "0.5" : "1",
    padding: "15px",
    textTransform: "uppercase" as any,
    transition: "all 0.4s",
    width: width,
    "&:hover": {
      backgroundColor: disabled
        ? "var(--bg-info)"
        : bgcolor === "outlined"
        ? "var(--bg-main)"
        : "white",
      border: disabled
        ? "var(--bg-disabled)"
        : bgcolor === "outlined"
        ? "3px solid var(--bg-main)"
        : `2px solid ${bgcolor}`,
      color: disabled
        ? "var(--text-disabled)"
        : bgcolor === "outlined"
        ? "white"
        : bgcolor,
      cursor: disabled ? "not-allowed" : "pointer",
      fontWeight: disabled ? "100" : "bold",
    },
    "&:active": {
      transform: disabled ? "none" : "scale(0.95)",
      transition: "0.3s all",
    },
  };
};

export const add_button_styles = (disabled?: boolean, align?: boolean) => {
  return {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: disabled ? "var(--bg-info)" : "var(--bg-secondary)",
    border: disabled ? "var(--bg-disabled)" : "1px solid var(--bg-secondary)",
    borderRadius: "50%",
    color: "white !important",
    cursor: disabled ? "not-allowed" : "normal",
    display: "flex",
    fontSize: "30px",
    height: "38px",
    opacity: disabled ? "0.5" : "1",
    textAlign: "center" as any,
    textTransform: "uppercase" as any,
    transition: "all 0.4s",
    verticalAlign: "middle",
    width: "38px",
    minWidth: "38px",
    margin: align ? 0 : "0 0 0 10px",
    "&:hover": {
      backgroundColor: disabled ? "var(--bg-info)" : "white",
      border: disabled
        ? "1px solid var(--bg-disabled)"
        : "1px solid var(--bg-secondary)",
      cursor: disabled ? "not-allowed" : "pointer",
      color: disabled ? "var(--text-disabled)" : "var(--bg-info) !important",
      fontWeight: disabled ? "100" : "bold",
    },
    "&:active": {
      transform: "scale(0.95)",
      transition: "0.3s all",
    },
  };
};

export const delete_button2_styles = (disabled?: boolean, align?: boolean) => {
  return {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--bg-disabled)",
    border: "var(--bg-disabled)",
    borderRadius: "50%",
    color: "white !important",
    cursor: disabled ? "not-allowed" : "normal",
    display: "flex",
    fontSize: "30px",
    height: "38px",
    opacity: disabled ? "0.5" : "1",
    textAlign: "center" as any,
    textTransform: "uppercase" as any,
    transition: "all 0.4s",
    verticalAlign: "middle",
    width: "38px",
    minWidth: "38px",
    margin: align ? 0 : "0 0 0 10px",
    "&:hover": {
      backgroundColor: disabled ? "var(--bg-info)" : "white",
      border: disabled
        ? "1px solid var(--bg-disabled)"
        : "1px solid var(--bg-secondary)",
      cursor: disabled ? "not-allowed" : "pointer",
      color: disabled ? "var(--text-disabled)" : "var(--text-secondary)",
      fontWeight: disabled ? "100" : "bold",
    },
    "&:active": {
      transform: "scale(0.95)",
      transition: "0.3s all",
    },
  };
};

export const button_option_styles = (isDisabled) => {
  return {
    color: isDisabled ? "var(--text-disabled)" : "var(--text-main)",
  }

}

// ------------------------ Cards ------------------------

export const card_container_styles = { padding: "5px" };

export const divider_styles = { margin: "8px 0" };

export const title_card_container_styles = {
  alignItems: "center",
  display: "inline",
  wordBreak: "break-word",
};

export const title_card_typography_styles = {
  color: "var(--text-main)",
  fontSize: "14px",
  fontWeight: "800",
  lineHeight: "1.1",
  padding: "0 !important",
  textTransform: "uppercase",
  width: "98%",
};

export const subtitle_card_typography_styles = {
  color: "var(--text-main)",
  display: "inline",
  fontWeight: "700",
  textAlign: "left",
  textTransform: "uppercase",
};

export const subtitle_value_styles = {
  color: "var(--text-info)",
  display: "inline",
  fontWeight: "300",
};

export const card_title_container_styles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "42px",
  wordBreak: "break-word",
};

//------------------------- Data Model -----------------------------

export const shadow_box_styles = (margin?: boolean) => {
  return {
    width: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.2) -6px 2px 8px -1px, rgba(0, 0, 0, 0.14) 2px 6px 20px 1px, rgba(0, 0, 0, 0.12) 0px 5px 13px 1px",
    padding: "15px",
    borderRadius: "10px",
    marginBottom: margin ? "20px" : "0",
  };
};

export const no_data_box_styles = {
  width: "95%",
  height: "50vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const no_data_typography_styles = {
  fontWeight: "700",
  color: "var(--text-info)",
};

export const select_measure_card_container_styles = {
  alignItems: "center",
  border: "1px solid rgba(0,0,0,0.2)",
  borderRadius: "20px",
  boxShadow: "5px 5px rgba(0,0,0,0.5)",
  gap: 1,
  height: "410px",
  justifyContent: "center",
  padding: "20px",
  userSelect: "none",
  width: "100%",
};

export const divider_measure_card_styles = {
  width: "100%",
  backgroundColor: "var(--bg-main)",
};

export const list_measure_card_styles = {
  overflowY: "auto",
  height: "300px",
  width: "300px",
};

// ------------------------ General --------------------------------

export const blue_color_styles = { color: "var(--text-main) !important" };

export const bold_styles = { fontWeight: "bold" };

export const background_grey_styles = { backgroundColor: "var(--bg-disabled)" };

export const edit_name_modal_container_styles = {
  display: "flex",
  alignItems: "center",
  minWidth: "500px",
};

export const stepper_container_styles = {
  height: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  overflowY: "hidden",
};

export const cursor_pointer_styles = {
  cursor: "pointer",
  "&:hover": { color: "var(--text-main)" },
};

export const row_sb_100_styles = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
};

export const row_center_100_styles = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
};

export const active_inactive_typography_styles = {
  color: "var(--text-main)",
  fontWeight: "600",
  fontSize: "18px",
  textTransform: "uppercase",
};

export const centered_container_styles = {
  alignItems: "center",
  flexDirection: "row",
  height: "100%",
  justifyContent: "center",
  width: "100%",
};

export const mb_20_styles = { marginBottom: "20px !important" };

export const no_elements_box_styles = {
  alignItems: "center",
  display: "flex",
  height: "20vh",
  justifyContent: "center",
  width: "95%",
};

export const home_background_styles = (backgroundImage) => {
  return {
    alignItems: "center",
    backgroundColor: "var(--bg-main)",
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 40px)",
    justifyContent: "flex-start",
    width: "100%",
  };
};

export const blue_label_styles = {
  backgroundColor: "var(--bg-secondary)",
  borderRadius: "4px",
  color: "white",
  height: "40px",
  padding: "8px",
  textAlign: "center",
  textTransform: "uppercase",
  verticalAlign: "middle",
  width: "70%",
  wordBreak: "break-all",
};

export const chip_filters_container = (margin) => {
  return {
    flexDirection: "row",
    spacing: 1,
    margin: margin ? margin : "15px 0 0 0",
    justifyContent: "flex-start",
    width: "100%",
  };
};

export const modal_container_minheight_styles = (minHeight) => {
  return {
    gap: 2,
    height: "100%",
    justifyContent: "space-between",
    minHeight: "200px",
    width: "100%",
  };
};

export const right_bottom_button_container_styles = {
  alignItems: "flex-end",
  alignSelf: "flex-end",
  direction: "row",
  height: "100%",
  justifyContent: "flex-end",
  width: "100%",
};

export const right_bottom_button_styles = {
  alignSelf: "flex-end",
  fontSize: "12px",
  fontWeight: "bold",
  minWidth: "140px",
  textDecoration: "underline",
};

export const container_gap_styles = (gap) => {
  return {
    alignItems: "center",
    gap: gap,
    width: "100%",
  };
};

export const mb_container_styles = (margin) => {
  return {
    margin: margin,
    width: "100%",
  };
};

export const example_dialog_container_styles = {
  alignItems: "center",
  color: "var(--text-main)",
  gap: 2,
  height: "100%",
  justifyContent: "space-between",
  minHeight: "200px",
  width: "100%",
};

export const tooltip_text_styles = {
  color: "var(--text-main)",
  fontWeight: "800",
  textTransform: "uppercase",
  fontSize: "14px",
  lineHeight: "1.1",
  width: "98%",
  padding: "0 !important",
};

export const view_compact_icon_styles = {
  fontSize: "30px",
  transition: "all 0.5s ease",
};

export const view_compact_space_styles = {
  backgroundColor: "transparent",
  height: "30px",
  width: "100%",
};

export const modal_container_450 = {
  height: "100%",
  justifyContent: "space-between",
  minHeight: "450px",
  width: "100%",
};

export const icon_box_styles = {
  padding: "0 2px",
  ":hover": {
    cursor: "pointer",
  },
};

export const icon_table_styles = (color, hoverColor) => {
  return {
    color: color,
    ":hover": {
      color: hoverColor,
    },
  };
};

//------------------------- Grid --------------------------

export const grid_container_styles = {
  alignItems: "center",
  color: "var(--text-main) !important",
  marginLeft: "0px",
  width: "100%",
};

export const grid_container2_styles = {
  alignContent: "flex-start",
  marginLeft: "0px",
  minHeight: "250px",
  width: "100%",
};

export const row_delete_container_styles = {
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
  width: "100%",
};

export const charts_grid_box_styles = {
  height: "100%",
  margin: "10px 0",
  overflowY: "hidden",
  width: "95%",
};

export const box_grid_draggable_styles = {
  border: "1px var(--bg-light) solid",
  overflowY: "hidden",
  position: "relative",
  transition: "all 0.5s ease",
};

export const chart_box_draggable_styles = (onHover) => {
  return {
    alignItems: "center",
    backgroundColor: onHover ? "rgba(0,0,0,0.07)" : "transparent",
    height: "30px",
    position: "absolute",
    transition: "all 0.5s ease",
    width: "100%",
    zIndex: 10,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.13)",
      cursor: "pointer",
    },
  };
};

export const grid_element_styles = (isElementSelected) => {
  return {
    alignItems: "center",
    backgroundColor: isElementSelected ? "var(--bg-main)" : "white",
    border: "none",
    borderRadius: "10px",
    color: isElementSelected ? "white" : "var(--text-main)",
    display: "flex",
    flexDirection: "row",
    gap: 0.5,
    height: "70px",
    justifyContent: "center",
    minWidth: "auto",
    padding: "10px",
    textTransform: "uppercase",
    width: "100%",
  };
};

export const grid_chip_styles = (isElementSelected) => {
  return {
    backgroundColor: isElementSelected
      ? "rgba(100, 157, 172, 1)"
      : "rgba(22, 115, 138,0.2)",
    borderRadius: "50%",
    color: "var(--text-main)",
    cursor: "pointer",
    fontSize: "20px",
    height: "45px",
    lineHeight: "45px",
    width: "45px",
  };
};

export const grid_item_element_styles = (
  getBackgroundColor,
  element,
  handleWidth
) => {
  return {
    alignItems: "center",
    backgroundColor: getBackgroundColor(element, false),
    border: "none",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minHeight: "40px",
    minWidth: "auto",
    padding: "0 8px",
    position: "relative",
    textTransform: "uppercase",
    transition: "all 0.5s",
    width: `${handleWidth()}%`,
    "& h4": {
      color: "var(--text-main)",
      fontSize: "14px",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: getBackgroundColor(element, true),
    },
  };
};

export const draggable_element_styles = (
  backgroundColor?,
  width?,
  hoverBackgroundColor?
) => {
  return {
    alignItems: "center",
    backgroundColor: backgroundColor
      ? backgroundColor
      : "var(--bg-success-hover)",
    border: "none",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minHeight: "40px",
    minWidth: "auto",
    padding: "0 8px",
    position: "relative",
    textTransform: "uppercase",
    transition: "all 0.5s",
    width: width ? width : "100%",
    "& h4": {
      color: "var(--text-main)",
      fontSize: "14px",
      fontWeight: "bold",
    },
    "&:hover": {
      backgroundColor: hoverBackgroundColor
        ? hoverBackgroundColor
        : "var(--bg-success-hover)",
    },
  };
};

export const button_right_position_styles = {
  position: "absolute",
  right: "-50px",
  "&:hover": {
    backgroundColor: "rgba(23,45,64, 0.9)",
  },
};

export const element_typography_styles = {
  alignItems: "center",
  color: "var(--text-main)",
  display: "flex",
  flexDirection: "row",
  fontSize: "14px",
  fontWeight: "bold",
  gap: 0.4,
  justifyContent: "space-between",
  width: "100%",
};

// ------------------------ Login Views -----------------------------

export const company_container_styles = {
  alignItems: "center",
  backgroundColor: "transparent",
  height: "100vh",
  justifyContent: "center",
  width: "100%",
};

export const company_form_container_styles = {
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
  margin: "0 auto",
  maxWidth: "35%",
  minWidth: "400px",
};

export const company_logo_container_styles = {
  margin: "15px 15px",
};

export const company_logo_styles = {
  marginTop: "20px",
  width: "120px",
};

export const company_title_styles = {
  color: "var(--text-main)",
};

export const company_form_box_styles = {
  alignItems: "center",
  color: "black",
  margin: "0 auto",
  width: "80%",
};

export const container_100_styles = {
  width: "100%",
};

export const error_text_styles = {
  fontWeight: "bold",
  color: "var(--text-error)",
  textAlign: "center",
  mt: "10px",
};

export const error_no_data_styles = {
  alignItems: "center",
  backgroundColor: "var(--bg-light)",
  height: "100%",
  justifyContent: "center",
  minHeight: "130px",
  width: "100%",
};

export const company_buttons_container_styles = {
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "20px 0",
  width: "100%",
};

export const stages_settings_table_styles = {
  height: "100%",
  "& .MuiDataGrid-cellCheckbox": {
    minWidth: "80px",
    width: "fit-content !important",
    maxWidth: "fit-content !important",
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    maxWidth: "fit-content !important",
    width: "fit-content !important",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "var(--bg-secondary)",
    height: "40px",
    fontSize: "15px",
  },
  "& .MuiDataGrid-columnHeadersInner span": {
    color: "white",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .viewMoreIconCheckbox": {
    display: "block !important",
    marginTop: "5px !important",
  },
};

// ------------------------ Menu --------------------------

import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { Etapa } from "../components/parameterization/types";
const drawerWidth = 280;
export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const companySelectorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 390,
  height: 280,
  bgcolor: "transparent",
  borderRadius: 50,
  "&:focus": {
    outline: "none",
    border: "none",
  },
};

export const hovered_list_item_styles = {
  "& .MuiListItemButton-root:hover": {
    borderRadius: "10px",
    backgroundColor: "var(--bg-secondary)",
  },
};

export const list_item_text_styles = (open: boolean) => {
  return {
    opacity: open ? 1 : 0,
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  };
};

export const list_item_button_styles = (open: boolean) => {
  return {
    minHeight: 20,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    color: "white",
  };
};

export const list_item_icon_styles = (open: boolean) => {
  return {
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
    margin: "0 8px 0 8px",
  };
};

export const list_item_selected_styles = (
  isOptionSelected: boolean | undefined
) => {
  return {
    display: "block",
    "& .MuiListItemButton-root": {
      margin: "1.5px 6px 0 5px",
      padding: "5px 5px",
      borderRadius: "10px",
      backgroundColor: isOptionSelected ? "var(--bg-disabled)" : "",
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "var(--bg-disabled)",
    },
  };
};

export const menu_items_container = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const menu_logo_user_container = {
  display: "flex",
  alignItems: "center",
};

export const menu_logo_container = {
  display: "flex",
  alignItems: "center",
  marginRight: "20px",
  "&:hover": {
    cursor: "pointer",
  },
};

export const menu_logo = {
  height: "30px",
  marginRight: "10px",
};

export const menu_logo_container_header = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const menu_header_option_container = {
  display: "flex",
  "&:hover": {
    cursor: "pointer",
    color: "var(--chart-22)",
  },
};

export const menu_header_option = {
  margin: "0 15px",
  "&:hover": {
    cursor: "pointer",
    color: "var(--chart-22)",
  },
};

export const menu_header_company = {
  margin: "0 12px",
  "&:hover": {
    cursor: "pointer",
    color: "var(--chart-22)",
  },
};

export const menu_phi = {
  fontFamily: "'Stint Ultra Expanded', sans-serif",
  fontSize: "18px",
  fontWeight: 900,
  letterSpacing: "4px",
  color: "white",
};

// ------------------------ Parameterization ------------------------------

export const reset_button_styles = (isLoading: boolean) => {
  return {
    alignItems: "center",
    color: isLoading ? "var(--text-disabled)" : "var(--text-main)",
    cursor: "pointer",
    display: "flex",
    fontWeight: "700",
    marginLeft: "30px",
  };
};

export const expand_more_icon_styles = {
  padding: "0",
  paddingRight: "5px",
  paddingTop: "7px",
};

export const reset_button_item_styles = (selectedStage: Etapa) => {
  return {
    color:
      selectedStage?.etapa === 0 ? "var(--text-disabled)" : "var(--text-main)",
    ":hover": {
      cursor: selectedStage?.etapa !== 0 ? "pointer" : "default",
    },
  };
};

export const table_modal_global_styles = {
  ".MuiDataGrid-menuList": {
    backgroundColor: "white",
    "& .MuiMenuItem-root": {
      color: "var(--text-main)",
    },
  },
};

export const table_modal_styles2 = (rowsResult) => {
  return {
    height: rowsResult ? 200 + rowsResult?.length * 40 : "130px",
    "& .MuiDataGrid-cellCheckbox": {
      minWidth: "80px",
      width: "fit-content !important",
      maxWidth: "fit-content !important",
    },
    "& .MuiDataGrid-columnHeaderCheckbox": {
      maxWidth: "fit-content !important",
      width: "fit-content !important",
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "var(--bg-secondary)",
      height: "45px",
      fontSize: "15px",
    },
    "& .MuiDataGrid-columnHeadersInner span": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .viewMoreIconCheckbox": {
      display: "block !important",
      marginTop: "5px !important",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      wordBreak: "break-all",
      whiteSpace: "normal",
    },
  };
};

export const table_modal_styles = {
  height: "500px",
  "& .MuiDataGrid-cellCheckbox": {
    minWidth: "80px",
    width: "fit-content !important",
    maxWidth: "fit-content !important",
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    maxWidth: "fit-content !important",
    width: "fit-content !important",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "var(--bg-secondary)",
    height: "45px",
    fontSize: "15px",
  },
  "& .MuiDataGrid-columnHeadersInner span": {
    color: "white",
  },
  "& .MuiDataGrid-columnHeaderCheckbox .viewMoreIconCheckbox": {
    display: "block !important",
    marginTop: "5px !important",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    wordBreak: "break-all",
    whiteSpace: "normal",
  },
};

export const expand_button_styles = {
  color: "white",
  marginLeft: "7px",
  cursor: "pointer",
};

export const useColumnStyles: any = makeStyles((theme) => ({
  headerColumn: {
    backgroundColor: "var(--bg-secondary)",
    height: "40px",
  },
}));

export const criteria_buttons_container = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  spacing: 1,
};

export const criteria_buttons_tooltip_styles = {
  verticalAlign: "center",
  fontSize: "16px",
  zIndex: "100",
};

export const criteria_buttons_styles = {
  alignItems: "center",
  background: "transparent",
  color: "var(--text-main)",
  display: "flex",
  fontSize: "14px",
  fontWeight: "bold",
  justifyContent: "center",
  ":hover": {
    color: "#F90D4A",
  },
};

export const zoomin_icon_styles = {
  color: "var(--text-secondary)",
  marginLeft: "2px",
  ":hover": { cursor: "pointer" },
};

export const typography_total_styles = {
  alignItems: "center",
  display: "flex",
  margin: "2px 5px 2px 15px",
  color: "var(--text-main)",
  background: "transparent",
  fontSize: "14px",
  fontWeight: "bold",
  textTransform: "uppercase",
};

export const total_number_styles = { fontSize: "18px", marginLeft: "10px" };

export const custom_model_cell_container = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

export const criteria_modal_error_styles = {
  color: "var(--text-error)",
  fontWeight: "bold",
};

export const step_form_label_styles = {
  color: "var(--text-main)",
  fontWeight: 500,
};

export const list_validation_item_text_styles = {
  color: "var(--text-main)",
  "& span": { fontWeight: "bold" },
};

export const collapse_validation_typography = {
  color: "var(--text-secondary)",
  pl: 2,
  pt: 1,
};

export const param_total_expense_styles = {
  fontSize: "1.1rem",
  lineHeight: 1.4,
  textAlign: "center",
};

export const view_dummy_container_styles = {
  alignItems: "center",
  justifyContent: "center",
  padding: "0 100px 30px 100px",
  position: "relative",
  spacing: 2,
};

export const view_dummy_table_title = {
  color: "var(--text-main)",
  fontWeight: "bold",
  textTransform: "uppercase",
  marginBottom: "10px",
};

export const view_dummy_value_styles = {
  color: "var(--text-main)",
  margin: "5px",
};

export const stages_settings_container = {
  width: "100%",
  marginTop: "10px",
  alignItems: "center",
};

export const stages_buttons_container = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingRight: "9px",
  width: "100%",
};

export const stages_typography_styles = {
  alignSelf: "flex-start",
  color: "var(--text-main)",
  minHeight: "20px",
};

export const stages_container = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
};

export const bucket_button_styles = (id, bucketSelectedOption) => {
  return {
    alignItems: "center",
    backgroundColor:
      id === bucketSelectedOption?.value ? "var(--bg-main)" : "white",
    border: "none",
    borderRadius: "20px",
    color: id === bucketSelectedOption?.value ? "white" : "var(--text-main)",
    display: "flex",
    flexDirection: "column",
    height: "50px",
    justifyContent: "space-between",
    minWidth: "150px",
    padding: "8px",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  };
};

export const buckets_stages_container_styles = (isSticky: boolean) => {
  return {
    position: "sticky",
    top: "60px",
    zIndex: "15",
    backgroundColor: isSticky ? "white" : "white",
    overflow: "hidden",
    boxShadow: "0px 18px 10px -10px rgb(0 0 0 /20%)",
  };
};

export const stages_calculationButtons_styles = (isSticky: boolean) => {
  return {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: isSticky ? "98%" : "auto",
  };
};
// ------------------------ Tables ------------------------

export const columnTitleStyle = {
  color: "white",
  textTransform: "uppercase",
  fontWeight: "bold",
  fontSize: "14px",
  textOverflow: "ellipsis",
  lineHeight: 1.2,
  overflowWrap: "break-word",
  textAlign: "center",
};

export const editIconStyle = {
  marginRight: "7px",
  marginBottom: "-3px",
  cursor: "pointer",
  color: "#a6a6a8",
  "& :hover": { color: "var(--text-main)" },
};

export const titles_row_container_styles = {
  //reemplaza a .cc_external_row_container con un stack
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "5px",
};

export const column_styles = (bgcolor?: string, color?: string) => {
  return {
    backgroundColor: bgcolor ? bgcolor : "var(--bg-secondary)",
    color: color ? color : "white",
    fontWeight: "bold",
    borderRadius: "5px",
  };
};

export const text_styles = {
  color: "var(--text-main)",
  padding: 0,
};

export const header_report_table_styles = {
  alignItems: "center",
  color: "var(--cream)",
  flexDirection: "row",
  fontSize: "12px",
  fontWeight: "bold",
  height: "auto",
  justifyContent: "center",
  lineHeight: "1.1",
  margin: 0,
  textAlign: "center",
  whiteSpace: "normal",
  width: "100%",
  wordBreak: "break-word",
};

export const rendercell_container_report_table_styles = {
  alignItems: "center",
  direction: "row",
  height: "100%",
  justifyContent: "center",
  padding: 0,
  width: "100%",
};

export const rendercell_report_table_styles = (
  color,
  backgroundColor,
  pointer
) => {
  return {
    alignItems: "center",
    backgroundColor: backgroundColor,
    color: color,
    flexDirection: "row",
    fontWeight: "bold",
    height: "100%",
    justifyContent: "center",
    width: "100%",
    "&:hover": {
      cursor: pointer ? "pointer" : "default",
      backgroundColor: pointer ? "var(--bg-main)" : backgroundColor,
      color: pointer ? "white" : color,
    },
  };
};

export const datagrid_styles = {
  boxShadow: 3,
  fontSize: "12px",
  padding: "0 10px 10px 10px",
};

export const grid_toolbar_container_styles = {
  alignItems: "center",
  color: "#F90D4A",
  display: "flex",
  flexDirection: "row",
  height: "65px",
  justifyContent: "space-between",
  padding: "0 10px",
  width: "100%",
};

export const stack_toolbar_container_styles = {
  flexDirection: "row",
  spacing: 4,
  width: "100%",
  justifyContent: "space-between",
};

export const columns_button_styles = {
  display: "flex",
  color: "var(--text-main)",
  width: "90px",
  background: "transparent",
  margin: "2px 5px 2px 15px",
  fontSize: "14px",
  fontWeight: "bold",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    color: "#F90D4A",
  },
};

export const export_button_styles = {
  display: "flex",
  color: "var(--text-main)",
  background: "transparent",
  fontSize: "14px",
  fontWeight: "bold",
  alignItems: "center",
  justifyContent: "center",
  ":hover": {
    color: "#F90D4A",
  },
};

// ------------------------ Texts ------------------------

export const title_styles = {
  fontSize: "var(--title)",
  fontWeight: "bold",
};

export const form_subtitle_styles = {
  color: "var(--bg-secondary)",
  fontSize: "18px",
  fontWeight: "bold",
  margin: "15px 0",
};

export const form_label = {
  color: "var(--text-main)",
  fontWeight: "500",
  fontSize: "var(--text)",
};

export const form_label_asterisc = {
  color: "var(--text-error)",
  fontWeight: 700,
};

export const active_inactive_text = {
  color: "var(--text-main)",
  fontWeight: "600",
  fontSize: "18px",
  margin: "8px 0 15px 0",
};

export const non_active_text = {
  fontWeight: "700",
  color: "var(--text-info)",
};

export const non_active_container = {
  alignItems: "center",
  direction: "row",
  height: "20vh",
  justifyContent: "center",
  width: "95%",
};

export const cards_error_text = {
  color: "var(--text-error)",
  fontWeight: "bold",
  textAlign: "center",
};

export const inactive_cards_text = {
  color: "var(--text-main)",
  fontWeight: "600",
  fontSize: "18px",
  marginTop: "40px !important",
  marginBottom: "15px",
};

export const back_arrow_styles = {
  fontSize: "35px",
  marginRight: "10px",
  "& :hover": { cursor: "pointer", color: "-var(--icon-error)" },
};

export const list_item_formula_text_styles = {
  alignContent: "center",
  alignItems: "center",
  color: "var(--text-main)",
  justifyContent: "center",
  width: "250px",
  wordWrap: "break-word",
};

export const list_item_formula_span_styles = {
  alignItems: "center",
  bgcolor: "var(--bg-secondary)",
  borderRadius: "50%",
  color: "white",
  fontSize: "0.7rem",
  fontWeight: "bold",
  height: 20,
  justifyContent: "center",
  width: 20,
};

export const form_helper_text_styles = {
  color: "var(--text-error)",
  margin: 0,
};

export const warning_text_container = {
  alignItems: "center",
  color: "var(--text-main)",
  flexDirection: "row",
  fontSize: "12px",
  padding: "3px 0",
};

export const warning_icon_styles = {
  color: "var(--yellow)",
  fontSize: "20px",
  paddingRight: "3px",
  pb: "3px",
};

export const table_header_text_styles = { fontWeight: "bold", color: "white" }

// ------------------------ Verification ------------------------

export const verification_container_styles = {
  alignItems: "center",
  backgroundColor: "var(--bg-main)",
  color: "var(--text-main)",
  flexDirection: "row",
  height: "100vh",
  justifyContent: "center",
  width: "100%",
};

export const verification_form_styles = {
  alignItems: "center",
  background: "white",
  borderRadius: "10px",
  boxShadow: "5px 5px 5px 5px rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "35%",
  minWidth: "400px",
  paddingTop: "20px",
};

export const verification_form_box_styles = {
  alignItems: "center",
  color: "black",
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  maxWidth: "450px",
};

export const verification_logo_container = {
  margin: "15px 15px",
};

export const verification_description_styles = {
  textAlign: "justify",
  width: "70%",
  margin: "10px 0",
};

export const verification_password_container_styles = {
  position: "relative",
  width: "280px",
};

export const verificaction_form_input_styles = {
  appearance: "none" as any,
  borderRadius: "4px",
  border: "1px solid var(--bg-main)",
  color: "var(--text-main)",
  display: "block",
  fontSize: "14px",
  marginBottom: "10px",
  padding: "10px 15px",
  width: "100%",
};

export const verification_message_styles = {
  color: "var(--text-success)",
  fontSize: "14px",
  fontWeight: "bold",
  margin: "0",
  textAlign: "center",
  width: "400px",
  paddingTop: "3px",
};

export const verification_password_icon_styles = {
  bottom: "7px",
  color: "var(--text-main)",
  position: "absolute",
  right: "10px",
  top: "7px",
  ":hover": { cursor: "pointer" },
  ":active": { cursor: "pointer" },
};

export const verification_resend_styles = {
  color: "var(--button-cancel)",
  fontSize: "14px",
  marginTop: "10px",
  textAlign: "center",
  textDecoration: "underline",
  ":hover": { cursor: "pointer", textDecoration: "none" },
};

// ------------------------ Layout ------------------------

export const component_layout_container_styles = {
  justifyContent: "center",
  alignItems: "center",
  width: "95%",
  marginTop: "35px",
};

export const title_layout_container_styles = (
  rightComponent?: JSX.Element,
  marginBottom?: string
) => {
  return {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: rightComponent ? "space-between" : "flex-start",
    width: "100%",
    marginBottom: marginBottom ? marginBottom : "20px",
    borderBottom: "1px var(--bg-light) solid",
  };
};

export const icon_layout_styles = {
  flexDirection: "row",
  alignItems: "center",
  color: "var(--icon-main)",
  gap: "10px",
};

export const title_layout_styles = {
  fontSize: "1.4rem",
  fontWeight: "600",
  color: "var(--text-main)",
  textTransform: "uppercase",
  fontFamily: "Verdana",
  marginBottom: "8px",
};

export const layout_menu_container_styles = {
  width: "50px",
  zIndex: "10",
};

export const layout_container_styles = {
  display: "flex",
  alignItems: "baseline",
};

export const layout_outlet_container_styles = {
  zIndex: "1",
  width: "100%",
  display: "flex",
  flexdirection: "column",
  justifyContent: "center",
  alignItems: "center",
  top: "90px",
  marginTop: "40px",
};

export const white_shadow_box_styles = {
  alignItems: "flex-start",
  backgroundColor: "white",
  borderRadius: "5px",
  boxShadow: "2px 2px 10px 2px rgba(0, 0, 0, 0.2)",
  color: "var(--text-main)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  margin: "0 0 30px 0 !important",
  minWidth: "500px",
  padding: "14px 23px",
};

export const form_steps_container = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  width: "85%",
};

// ------------------------ Login ------------------------

export const persist_login_container_styles = {
  width: "100vw",
  height: "100vh",
  background: "var(--bg-main)",
  justifyContent: "center",
  alignItems: "center",
};

export const login_container_styles = {
  alignItems: "center",
  backgroundColor: "transparent",
  display: "flex",
  height: "100vh",
  justifyContent: "center",
  width: "100%",
};

export const video_container_styles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  zIndex: -10,
};

export const video_background_styles = {
  position: "absolute",
  top: 0,
  left: 0,
  minWidth: "100%",
  minHeight: "100%",
  width: "auto",
  height: "auto",
  zIndex: -1,
};

export const login_title_styles = {
  borderBottom: "1px solid var(--bg-main)",
  color: "var(--text-main)",
  fontWeight: "100",
  textAlign: "center",
  fontSize: "2em",
  margin: "revert",
};

// ------------------------ Steps ------------------------

export const step_title_styles = {
  fontSize: "1.3rem",
  fontWeight: "bold",
  color: "var(--bg-secondary)",
  padding: "15px 0px 5px 0px",
};

export const step_container_styles = {
  height: "58vh",
  alignItems: "center",
  width: "100%",
  justifyContent: "flex-start",
  overflowY: "auto",
};

export const stepper_buttons_container_styles = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
};

export const stepper_buttons_container_styles2 = {
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

export const step_message_styles = (type) => {
  return {
    fontWeight: "700",
    fontSize: "16px",
    color:
      type === "error"
        ? "var(--text-error)"
        : type === "warning"
        ? "var(--orange)"
        : type === "text"
        ? "var(--text-main)"
        : "var(--text-secondary)",
    margin: 0,
    width: "100%",
    textAlign: "center",
  };
};

export const step_message_container_styles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginBottom: "20px",
  padding: "0 5px",
};

export const form_layouts_container_styles = {
  flexDirection: "row",
  justifyContent: "space-between",
  width: "90%",
};

export const form_layouts_container_styles2 = {
  flexDirection: "row",
  justifyContent: "center",
  width: "90%",
};

export const upload_file_icon_styles = {
  fontSize: "60px",
  color: "var(--text-info)",
  "&:hover": {
    cursor: "pointer",
  },
};

export const check_circle_icon_styles = {
  color: "var(--icon-success) !important",
  cursor: "pointer",
  transition: "all 1s linear",
  "& :hover": { color: "var(--icon-main)" },
};

export const delete_icon_styles = {
  color: "var(--bg-neutral-hover) !important",
  cursor: "pointer",
  transition: "all 1s linear",
  "& :hover": { color: "var(--icon-error)" },
};

export const param_list_item_text_styles = {
  color: "var(--text-main) !important",
  fontWeight: "bold",
  maxWidth: "340px",
};

export const param_list_item_text_primary = {
  fontWeight: "bold",
  margin: 0,
  padding: 0,
};

export const list_item_tooltip_styles = {
  verticalAlign: "center",
  fontSize: "16px",
};

export const key_column_label_styles = {
  alignItems: "center",
  backgroundColor: "var(--bg-secondary)",
  borderRadius: "4px",
  color: "white",
  display: "flex",
  flexDirection: "row",
  fontSize: "16px",
  justifyContent: "center",
  textTransform: "uppercase",
  width: "100%",
  wordBreak: "break-all",
};

export const key_column_select_styles = {
  flexDirection: "row",
  gap: 2,
  justifyContent: "space-between",
  width: "100%",
};

export const chips_container_styles = {
  alignItems: "center",
  border: "2px solid var(--bg-light)",
  borderRadius: "4px",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 0.8,
  height: "fit-content",
  minHeight: "80px",
  padding: "10px 5px",
  width: "100%",
};

export const param_steps_container = {
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  overflowY: "hidden",
};

export const max_level_radiobutton_container = {
  alignItems: "center",
  flexDirection: "row",
  gap: 1,
  justifyContent: "space-between",
  width: "100%",
};
export const max_level_radiobutton_box_styles = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "60%",
  minHeight: "40px",
};

export const column_type_checkbox_container = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "70%",
};

export const column_type_replace_container = {
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "flex-start",
  width: "100%",
};

// ------------------------ ViewInfo Modal --------------------------

export const info_modal_container_styles = {
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "0 100px 30px 100px",
  position: "relative",
  spacing: 2,
};

export const info_modal_title_styles = {
  color: "var(--text-main)",
  fontWeight: "bold",
  marginBottom: "10px",
  textAlign: "center",
  textTransform: "uppercase",
  width: "100%",
};

export const info_modal_subtitle_styles = {
  color: "var(--text-main)",
  margin: "5px",
};

export const info_modal_subtitle_value_styles = { fontWeight: "bold" };

export const info_modal_subtitle_value_styles2 = {
  textTransform: "capitalize",
  display: "inline",
};

export const info_modal_subtitle_value_styles3 = {
  display: "inline",
  marginLeft: "5px",
  textTransform: "uppercase",
};

export const info_modal_subtitle_key_styles = {
  fontWeight: "bold",
  display: "inline",
};

export const info_section_container_styles = {
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "5px",
  boxShadow: "2px 2px 10px 2px rgb(0 0 0 / 20%)",
  color: "var(--text-main)",
  justifyContent: "flex-start",
  margin: "0 0 30px 0 !important",
  minWidth: "500px",
  padding: "14px 23px",
};

export const info_modal_infobox_styles = {
  alignItems: "center",
  color: "var(--text-main)",
  flexDirection: "row",
  margin: "5px",
  gap: 1,
};

export const view_info_modal_title = {
  color: "var(--text-main)",
  fontSize: "15px",
  fontWeight: "bold",
  marginBottom: "10px",
  textTransform: "uppercase",
};

export const view_info_modal_value = {
  alignItems: "center",
  display: "flex",
  paddingTop: "2px",
  textAlign: "left",
};
