export const returnItemRole = (item: any, userRolData: any, t) => {
  const roleMapping = {
    [t("menuItemsOptions.companyParameterization.title")]: [
      "parametrizacion_de_la_empresa.visualizacion",
      "parametrizacion_de_la_empresa.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.dataManagement.title")]: [
      "administracion_de_datos.visualizacion",
      "administracion_de_datos.subir_archivos",
      "administracion_de_datos.eliminar_archivos",
      "administracion_de_datos.descargar_archivos",
    ],
    [t("menuItemsOptions.validations.title")]: [
      "validaciones.visualizacion",
      "validaciones.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.criteriaManagement.title")]: [
      "administracion_de_criterios.visualizacion",
      "administracion_de_criterios.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.preProrationParameterization.title")]: [
      "parametrizacion_de_preprorrateo_y_prorrateo.visualizacion",
      "parametrizacion_de_preprorrateo_y_prorrateo.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.configProRataExpenses.title")]: [
      "configuracion_de_gastos.visualizacion",
      "configuracion_de_gastos.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.modelParameterization.title")]: [
      "parametrizacion_de_preprorrateo_y_prorrateo.visualizacion",
      "parametrizacion_de_preprorrateo_y_prorrateo.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.indicatorManagement.title")]: [
      "administracion_de_dashboard.visualizacion",
      "administracion_de_dashboard.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.dashboard.title")]: [
      "dashboards.visualizacion",
      "dashboards.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.taskManager.title")]: [
      "gestor_de_tareas.visualizacion",
    ],
    [t("menuItemsOptions.dataExportModelling.title")]: [
      "administracion_de_medidas_agrupaciones_y_analisis.visualizacion",
      "descargar_reporte.visualizacion_y_edicion",
      "administracion_de_medidas_agrupaciones_y_analisis.visualizacion_y_edicion",
      "administracion_de_medidas_agrupaciones_y_analisis.visualizacion_y_creacion",
      "administracion_de_medidas_agrupaciones_y_analisis.ejecucion",
    ],
    [t("menuItemsOptions.generatedDownloads.title")]: [
      "descargas.visualizacion_y_descargas_usuario",
      "descargas.visualizacion_y_descargas_totales",
    ],
    [t("menuItemsOptions.marketBasketCollapseList.title")]: [
      "market_basket.visualizacion",
      "market_basket.visualizacion_y_creacion",
      "market_basket.visualizacion_y_edicion",
    ],
    [t("menuItemsOptions.simulator.title")]: [
      "simulador.visualizacion",
      "simulador.visualizacion_y_creacion",
      "simulador.visualizacion_y_edicion",
    ],
  };

  return (
    roleMapping[item.title]?.some((permission) =>
      permission.split(".").reduce((acc, key) => acc?.[key], userRolData)
    ) || false
  );
};

export const emptyRolConfig = {
  administrador: {
    visualizacion_y_edicion: false,
  },
  parametrizacion_de_la_empresa: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  administracion_de_datos: {
    visualizacion: false,
    subir_archivos: false,
    eliminar_archivos: false,
    descargar_archivos: false,
  },
  validaciones: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  administracion_de_criterios: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  configuracion_de_gastos: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  parametrizacion_de_preprorrateo_y_prorrateo: {
    visualizacion: false,
    visualizacion_y_edicion: false,
  },
  administracion_de_dashboard: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  dashboards: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  gestor_de_tareas: {
    visualizacion: false,
  },
  administracion_de_medidas_agrupaciones_y_analisis: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
    ejecucion: false,
  },
  descargar_reporte: {
    visualizacion_y_edicion: false,
  },
  descargas: {
    visualizacion_y_descargas_usuario: false,
    visualizacion_y_descargas_totales: false,
  },
  market_basket: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
  simulador: {
    visualizacion: false,
    visualizacion_y_creacion: false,
    visualizacion_y_edicion: false,
  },
};

export const returnRoles = (location, userRolData, t) => {
  const path = location.pathname.slice(1);

  // Rutas de acceso libre
  const freeAccessRoutes = ["", "inicio", "empresa"];
  if (freeAccessRoutes.includes(path)) return true;

  // Mapeo de rutas a permisos
  const roleMappings = {
    [t("menuItemsOptions.administrator.url")]:
      userRolData?.administrador.visualizacion_y_edicion,
    [t("menuItemsOptions.companyParameterization.url")]:
      userRolData?.parametrizacion_de_la_empresa.visualizacion ||
      userRolData?.parametrizacion_de_la_empresa.visualizacion_y_edicion,
    [t("menuItemsOptions.dataManagement.url")]: Object.values(
      userRolData?.administracion_de_datos || {}
    ).some(Boolean),
    [t("menuItemsOptions.validations.adminValidations.url")]: Object.values(
      userRolData?.validaciones || {}
    ).some(Boolean),
    [t("menuItemsOptions.validations.matchesDashboard.url")]: Object.values(
      userRolData?.validaciones || {}
    ).some(Boolean),
    [t("menuItemsOptions.criteriaManagement.url")]: Object.values(
      userRolData?.administracion_de_criterios || {}
    ).some(Boolean),
    [t("menuItemsOptions.preProrationParameterization.url")]: Object.values(
      userRolData?.parametrizacion_de_preprorrateo_y_prorrateo || {}
    ).some(Boolean),
    [t("menuItemsOptions.configProRataExpenses.url")]: Object.values(
      userRolData?.configuracion_de_gastos || {}
    ).some(Boolean),
    [t("menuItemsOptions.parameterizationCollapseList.assignStages.url")]:
      Object.values(
        userRolData?.parametrizacion_de_preprorrateo_y_prorrateo || {}
      ).some(Boolean),
    [t(
      "menuItemsOptions.parameterizationCollapseList.criteriaCalculation.url"
    )]: Object.values(
      userRolData?.parametrizacion_de_preprorrateo_y_prorrateo || {}
    ).some(Boolean),
    [t("menuItemsOptions.parameterizationCollapseList.calculationReport.url")]:
      Object.values(
        userRolData?.parametrizacion_de_preprorrateo_y_prorrateo || {}
      ).some(Boolean),
    [t("menuItemsOptions.indicatorManagement.url")]: Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    [t("menuItemsOptions.dashboardCollapseList.indicators.url")]: Object.values(
      userRolData?.dashboards || {}
    ).some(Boolean),
    [t("menuItemsOptions.dashboardCollapseList.graphics.url")]: Object.values(
      userRolData?.dashboards || {}
    ).some(Boolean),
    [t("menuItemsOptions.dashboardCollapseList.analysis.url")]: Object.values(
      userRolData?.dashboards || {}
    ).some(Boolean),
    [t("menuItemsOptions.dashboardCollapseList.powerBi.url")]: Object.values(
      userRolData?.dashboards || {}
    ).some(Boolean),
    [t("menuItemsOptions.taskManager.url")]:
      userRolData?.gestor_de_tareas.visualizacion,
    [t(
      "menuItemsOptions.dataExportModellingCollapseList.measureManagement.url"
    )]: Object.values(
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis || {}
    ).some(Boolean),
    [t(
      "menuItemsOptions.dataExportModellingCollapseList.groupingsManagement.url"
    )]: Object.values(
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis || {}
    ).some(Boolean),
    [t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisManagement.url"
    )]: Object.values(
      userRolData?.administracion_de_medidas_agrupaciones_y_analisis || {}
    ).some(Boolean),
    [t(
      "menuItemsOptions.dataExportModellingCollapseList.analysisExecution.url"
    )]: !!userRolData?.administracion_de_medidas_agrupaciones_y_analisis.ejecucion,
    [t("menuItemsOptions.dataExportModellingCollapseList.downloadReport.url")]:
      userRolData?.descargar_reporte.visualizacion_y_edicion,
    [t("menuItemsOptions.generatedDownloadsCollapseList.downloadAll.url")]:
      userRolData?.descargas.visualizacion_y_descargas_totales,
    [t("menuItemsOptions.generatedDownloadsCollapseList.downloadByUser.url")]:
      userRolData?.descargas.visualizacion_y_descargas_usuario,
    [t("menuItemsOptions.marketBasketCollapseList.analysis.url")]:
      Object.values(userRolData?.market_basket || {}).some(Boolean),
    [t("menuItemsOptions.marketBasketCollapseList.admin.url")]: Object.values(
      userRolData?.market_basket || {}
    ).some(Boolean),
    "configuracion/archivo/grafico": Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    "configuracion/archivo/indicador": Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    "configuracion/archivo/tabla": Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    "configuracion/analisis/indicador": Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    "configuracion/analisis/grafico": Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    "configuracion/analisis/tabla": Object.values(
      userRolData?.administracion_de_dashboard || {}
    ).some(Boolean),
    "configuracion/cruce-codigos": Object.values(
      userRolData?.validaciones || {}
    ).some(Boolean),
    "market-basket/configuracion-analisis": Object.values(
      userRolData?.market_basket || {}
    ).some(Boolean),
  };

  // Simulador
  const simuladorPaths = [
    "delta/sales-forecast",
    "delta/sales-forecast-resultado",
    "delta/administracion-reglas",
    "delta/simulaciones",
    "delta/configuracion-simulaciones",
    "delta/simulacion-resultado",
    "delta/administracion-rutas",
  ];
  if (simuladorPaths.includes(path)) {
    return Object.values(userRolData?.simulador || {}).some(Boolean);
  }

  // Casos específicos
  if (path === "delta/administracion-reglas/configuracion-regla") {
    return (
      userRolData?.simulador.visualizacion ||
      userRolData?.simulador.visualizacion_y_creacion
    );
  }
  if (path === "delta/administracion-rutas/configuracion-ruta") {
    return (
      userRolData?.simulador.visualizacion_y_creacion ||
      userRolData?.simulador.visualizacion_y_edicion
    );
  }

  return roleMappings[path] ?? false;
};