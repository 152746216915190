import { chartConfigurations } from "./configurations";
import { PUT_ESTADO_GRAFICO_ARCHIVO } from "../../../api/axios";
import { useState } from "react";
import CardActions from "../../cards/CardActions";
import CardHeader from "../../cards/CardHeader";
import ChartDetails from "./ChartDetails";
import Divider from "@mui/material/Divider";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import useApi from "../../../hooks/useApi";
import useLanguage from "../../../context/LanguageProvider";
import useInfoContext from "../../../hooks/useInfoContext";

interface ChartCardProps {
  item: any;
  handleEdit: () => void;
  handleCopy: () => void;
  handleInfo: () => void;
  handleDelete: () => void;
  refetchData: () => void;
}

const ChartCard: React.FC<ChartCardProps> = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
  refetchData,
}) => {
  const { t } = useLanguage();
  const [checked, setChecked] = useState(item.activo);
  const { userRolData }: any = useInfoContext();

  const onSuccessPutState = () => refetchData();
  const { isLoading: isLoadingPutState, callApi: putState } = useApi(
    undefined,
    "PUT",
    t("indicatorsManagement.putState.codes"),
    undefined,
    onSuccessPutState,
    undefined,
    false
  );

  const handleChangeSwitch = () => {
    setChecked((prev) => !prev);
    putState(PUT_ESTADO_GRAFICO_ARCHIVO(item.id), { activo: !item.activo });
  };

  const chartConfig = chartConfigurations[item.tipo] || {};

  return (
    <>
      <SimpleBackdrop
        open={isLoadingPutState}
        message={t("indicatorsManagementTexts.chartCard.modifyingChartState")}
      />
      <Stack
        justifyContent="space-between"
        sx={{ padding: "5px 8px 8px 8px", minHeight: "280px" }}
      >
        <Stack justifyContent="flex-start">
          <CardHeader title={item.nombre} />
          <Divider sx={{ margin: "10px 0" }} />
          <ChartDetails item={item} t={t} chartConfig={chartConfig} />
        </Stack>
        <CardActions
          checked={checked}
          handleChangeSwitch={
            userRolData?.administracion_de_dashboard.visualizacion_y_edicion
              ? handleChangeSwitch
              : undefined
          }
          item={item}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleInfo={handleInfo}
          handleDelete={handleDelete}
        />
      </Stack>
    </>
  );
};

export default ChartCard;
