import { hovered_list_item_styles } from "../../styles/app-styles";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import CollapseOption from "./CollapseOption";
import Stack from "@mui/material/Stack";

interface CollapseListProps {
  openMenuList: any;
  optionsList: any;
  handleDrawerClose: any;
  getViewTitle: any;
}

const CollapseList = ({
  openMenuList,
  optionsList,
  handleDrawerClose,
  getViewTitle,
}: CollapseListProps) => {
  return (
    <Stack>
      <Collapse in={openMenuList} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={hovered_list_item_styles}>
          {optionsList &&
            optionsList().length &&
            optionsList().map((option: any, index: number) => {
              return (
                <>
                  {!option.disabled && (
                    <CollapseOption
                      key={index}
                      OptionIcon={option.icon}
                      optionTitle={option.title}
                      optionUrl={option.url}
                      handleDrawerClose={handleDrawerClose}
                      getViewTitle={getViewTitle}
                    />
                  )}
                </>
              );
            })}
        </List>
      </Collapse>
    </Stack>
  );
};

export default CollapseList;
